import {protoUI, ui} from "../ui/core";
import {isArray, extend} from "../webix/helpers";

import richselect from "./richselect";


const api = {
	name: "lookupselect",
	$cssName: "richselect",
	_suggest_config: function(value){
		var isobj = !isArray(value) && typeof value == "object" && !value.name;
		var suggest = { view:"suggest" };

		if (this._settings.optionWidth)
			suggest.width = this._settings.optionWidth;
		else
			suggest.fitMaster = true;

		if (isobj)
			extend(suggest, value, true);

		var view = ui(suggest);
		var list = view.getList();
		if (typeof value == "string")
			list.load(value);
		else if (!isobj)
			list.parse(value);

		view.attachEvent("onBeforeShow", function(){
			list.filter(function(item) {
				return item.visible;
			});
		});

		return view;
	}
};

const view = protoUI(api, richselect.view);
export default {api, view};