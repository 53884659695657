const skin = {
	topLayout:"space",
	//bar in accordion
	barHeight:38,	/*EKM*/		//!!!Set the same in skin.less!!!
	tabbarHeight: 38,	/*EKM*/
	rowHeight:30,	/*EKM*/
	toolbarHeight:38,	/*EKM*/
	sidebarTitleHeight:38,	/*EKM*/
	listItemHeight:30,	/*EKM*/		//list, grouplist, dataview, etc.
	inputHeight: 34,	/*EKM*/
	buttonHeight: 34,	/*EKM*/
	inputPadding: 3,
	menuHeight: 34,
	labelTopHeight: 22,
	unitHeaderHeight:20,
	propertyItemHeight: 28,
	timelineItemHeight:70,

	inputSpacing: 4,
	borderWidth: 1,

	sliderHandleWidth: 14,
	sliderPadding: 10,
	sliderBorder: 1,
	vSliderPadding:15,
	vSliderHeight:100,
	switchHeight:22,
	switchWidth:50,

	//margin - distance between cells
	layoutMargin:{ space:10, wide:10, clean:0, head:4, line:-1, toolbar:4, form:4, accordion: 10  }, /*EKM*/
	//padding - distance insede cell between cell border and cell content
	layoutPadding:{ space:10, wide:0, clean:0, head:0, line:0, toolbar:3, form:15, accordion: 0  }, /*EKM*/
	//space between tabs in tabbar
	tabMargin:4,
	tabOffset: 0,
	tabBottomOffset: 4, /*EKM*/
	tabTopOffset:1,

	customCheckbox: true,
	customRadio: true,

	popupPadding: 8,

	dataPadding: 10,

	calendarWeekHeaderHeight: 24,
	padding:0,
	accordionType: "accordion",

	optionHeight: 30, /*EKM*/
	timelineColor:"#3498db",

	backColor:"#ffffff",

	//colorboard
	colorPadding: 4
};

export default skin;