import {preventEvent} from "../webix/html";
import {protoUI} from "../ui/core";
import {isUndefined, isArray, _to_array, isString, isObject} from "../webix/helpers";
import {$active} from "../webix/skin";

import text from "./text";


const api = {
	name: "custompicker",
	$cssName: "richselect",
	defaults: {
		template: function (obj, common) {
			return common._render_div_block(obj, common);
		},
		popupWidth: 200,
		icon: "wxi-dots",
		multiselect: false,
		separator: ", "
	},
	$skin: function () {
		this.defaults.inputPadding = $active.inputPadding;
	},
	$render: function (obj) {
		if (isUndefined(obj.value)) return;
		obj.value = this.$prepareValue(obj.value);
		this.$setValue(obj.value);
	},
	getInputNode: function () {
		return this._dataobj.getElementsByTagName("DIV")[1];
	},
	getText: function () {
		return this._settings.text || "";
	},
	$prepareValue: function (value) {
		if (!this._settings.multiselect) {
			return this._prepareSingleValue(value);
		}

		
		if (!isArray(value) && typeof value == "object") {
			value = [value];
		} else if (isString(value)) {
			if (this._settings.csvResult && value.indexOf(",") > 0) {
				value = value.split(",");
			} else {
				value = [value];
			}
		} else if (!value) {
			value = [];
		}

		for (var i = 0; i < value.length; i++) {
			value[i] = this._prepareSingleValue(value[i]);
		}

		return value;
	},
	_prepareSingleValue: function (value) {
		return isObject(value) && "id" in value ? value.id : value || "";
	},
	_get_visible_text: function (value) {
		if (!this._settings.multiselect) {
			return this._get_visible_text_single(value);
		}

		if (this._settings.options.length == 0 || (
			this._settings.options.length == 1 && (
				this._settings.options[0] == null || this._settings.options[0] == undefined || this._settings.options[0] == ""
			))
		) {
			return "";
		} else {
			return this._settings.options.length + " items selected";
		}
	},
	_get_visible_text_single: function (value) {
		//TODO: working with objects with a value field. String values have not been tested.
		if (value.value) {
			return value.value;
		} else if (this._settings.options && this._settings.options.id == value) {
			return this._settings.options.value;
		} else {
			return value;
		}
	},
	_set_visible_text: function () {
		var node = this.getInputNode();
		if (node.value == undefined){
			node.innerHTML = this._settings.text || this._get_div_placeholder();
		} else {
			node.value = this._settings.text || "";
		}
	},
	$compareValue: function (oldvalue, value) {
		if (value != value) ;
		return false; //TODO: in multiselect mode we get an array of values. probably should do a deep compare?
	},
	getOptions: function () {
		return this._settings.options || this.getValue();
	},
	setOptions: function (options, setvalue) {
		this._settings.options = (this._settings.multiselect && !isArray(options)) ? _to_array([options]) : options;
		if (isUndefined(setvalue) || setvalue) {
			this.setValue(options, "auto"); //"auto" or "user"?
		}
	},
	$setValue: function (value) {
		if (!this._rendered_input) return;
		if (!this._settings.options) {
			this.setOptions(value, false);
		} else if (this._settings.multiselect) {
			var opts = isArray(this._settings.options) && this._settings.options.length > 0 ? this._settings.options.map(obj =>
				isObject(obj) && "id" in obj ? obj.id : obj||""
			).join(",") : "";
			var vals = isArray(value) ? value.join(",") : value;
			if (vals != opts) {
				this.setOptions(value, false);
			}
		}
		this._settings.text = value ? this._get_visible_text(value) : "";
		this._set_visible_text();
		this._toggleClearIcon(this._settings.text);
	},
	getValue: function () {
		if (!this._settings.multiselect) {
			return this._get_value_single(this._settings.value);
		}

		var value = this._settings.value;
		var result = [];
		if (!this._settings.csvResult) {
			if (!value) return []; result = [].concat(value).map(a => this._get_value_single(a));

			result = [].concat(value).map(a => this._get_value_single(a));
		} else {
			if (!value) return "";

			result = []
				.concat(value)
				.map(a => this._get_value_single(a)).join(",");
		}

		return result;
	},
	_get_value_single: function (value) {
		//input was not rendered, we need to parse value from setValue method
		if (!this._rendered_input) {
			value = this.$prepareValue(value) || null;
		} else if (this._settings.editable) { //rendered and in edit mode
			value = this.getInputNode().value;
		}

		return value || null;
	},
	_ignoreLabelClick: function (e) {
		this.focus();
		preventEvent(e);
	}
};

const view = protoUI(api, text.view);
export default {api, view};